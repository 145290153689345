import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/views/home/bannerWeb.png'
import _imports_1 from '@/assets/images/views/home/bannerPad.png'
import _imports_2 from '@/assets/images/views/home/bannerPhone.png'


const _withScopeId = n => (_pushScopeId("data-v-2622b703"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "BannerArea" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "webBanner"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "padBanner"
}
const _hoisted_4 = {
  key: 2,
  src: _imports_2,
  alt: "phoneBanner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.currentDevice === 'web')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    ($setup.currentDevice === 'pad')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    ($setup.currentDevice === 'phone')
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}