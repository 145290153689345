import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/serviceItem',
    name: 'ServiceItem',
    component: () => import('../views/ServiceItem.vue')
  },
  {
    path: '/chineseStyleFlow',
    name: 'ChineseStyleFlow',
    component: () => import('../views/ChineseStyleFlow.vue')
  },
  {
    path: '/westernStyleFlow',
    name: 'WesternStyleFlow',
    component: () => import('../views/WesternStyleFlow.vue')
  },
  {
    path: '/styleSelect',
    name: 'StyleSelect',
    component: () => import('../views/StyleSelect.vue')
  },
  {
    path: '/chinesePrice',
    name: 'ChinesePrice',
    component: () => import('../views/ChinesePrice.vue')
  },
  {
    path: '/westernPrice',
    name: 'WesternPrice',
    component: () => import('../views/WesternPrice.vue')
  },
  {
    path: '/itemDetails',
    name: 'ItemDetails',
    component: () => import('../views/ItemDetails.vue')
  },
  {
    path: '/newMessage',
    name: 'NewMessage',
    component: () => import('../views/NewMessage.vue')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/frequentlyAskedQuestions',
    name: 'FrequentlyAskedQuestions',
    component: () => import('../views/FrequentlyAskedQuestions.vue')
  },
  {
    path: '/messageBoard',
    name: 'MessageBoard',
    component: () => import('../views/MessageBoard.vue')
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from) =>{
    return { top: 0 }
  },
  routes
})

export default router
