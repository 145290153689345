import { createStore } from 'vuex'

export default createStore({
  state: {
    device: '' // web, pad, phone
  },
  mutations: {
    ['device'](state, device) {
      state.device = device
    },
  },
  actions: {
    action_device({ commit }) {
      let device: string = ""
      if (window.innerWidth > 1200) device = "web"
      else if (window.innerWidth >= 768) device = "pad"
      else device = "phone"

      commit('device', device)
    }
  },
  getters: {
    getter_device: state => state.device, // 依據寬度分三種 web pad phone
  }
})
