import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "Button" }
const _hoisted_2 = {
  key: 0,
  class: "icon"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "content",
      onClick: $setup.ClickButton
    }, [
      ($setup.isShowIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: `${$setup.iconImage}`,
              alt: "phone"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(`${$setup.props.text}`), 1)
    ])
  ]))
}