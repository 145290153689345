import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "Home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["BannerArea"]),
    _createVNode($setup["PriceArea"]),
    _createVNode($setup["UnderstandFuneralArea"]),
    _createVNode($setup["NewMessageArea"])
  ]))
}